html {
  height: 100%;
}

body {
  font-family: "Inter var", sans-serif !important;
  height: 100%;
}

.main-navigation {
  display: none;
}

#printWindow {
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
}

svg.icon.icon-tabler {
  width: 16px;
  height: 16px;
}

.shiki {
  padding: 16px;
}

.hljs-comment {
  color: #6c7380 !important;
}

/* Pending status: incomplete, pending, awaiting payment, awaiting fulfillment, awaiting shipment, awaiting pickup, manual verification required */
/* Pending */
.order-0 {
  background: #879193;
}
.order-1 {
  background: #879193;
}
/* Shipped */
.order-2 {
  background: #bddf57;
}
/* Partially Shipped */
.order-3 {
  background: #4a6fb3;
}
/* Refunded */
.order-4,
.order-14 {
  background: #fccb05;
}
/* Cancelled */
.order-5 {
  background: #000;
}
/* Declined */
.order-6 {
  background: #7f5f3c;
}
/* Awaiting Payment */
.order-7 {
  background: #ff9000;
}
/* Awaiting Pickup */
.order-8 {
  background: #c979f2;
}
/* Awaiting Shipment */
.order-9 {
  background: #cd3101;
}
/* Completed */
.order-10 {
  background: #bddf57;
}
/* Awaiting Fulfillment */
.order-11 {
  background: #72cdfa;
}
/* Manual Verification Required */
.order-12 {
  background: #e7a0ae;
}
/* Disputed */
.order-13 {
  background: #9966ff;
}

.prose.ai-code {
  line-height: 24px;
  pre {
    margin: 0 !important;
    line-height: 21px !important;
    // padding-top: 0 !important;
    code {
      font-size: 14px !important;
      line-height: 21px !important;
    }
  }

  .progress-bar {
    background-color: #f2f2f2;
    border-radius: 20px;
    height: 20px;
    position: relative;
    margin: 20px 0;
    width: 100%;
    display: flex;
    overflow: hidden;
  }

  .progress {
    background-color: #4caf50;
    height: 100%;
    top: 0;
    left: 0;
    width: 20%;

    &[data-active="false"] {
      opacity: 0.25;
    }

    &[data-score="0"] {
      background-color: #4caf50;
    }
    &[data-score="1"] {
      background-color: #8bc34a;
      left: 20%;
    }
    &[data-score="2"] {
      background-color: #ffc107;
      left: 40%;
    }
    &[data-score="3"] {
      background-color: #ff9800;
      left: 60%;
    }
    &[data-score="4"] {
      background-color: #f44336;
      left: 80%;
    }
    &[data-score="5"] {
      background-color: #e91e63;
      left: 100%;
    }
  }
}

.prose {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    a {
      position: relative;
    }
    &:hover a[href^="#"] span:before {
      opacity: 1;
    }
    a[href^="#"] span {
      &:hover {
        color: #696c73;
      }
      &:before {
        content: "#";
        position: absolute;
        margin-left: -26px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  h1 {
    margin-bottom: 0 !important;
  }

  .h1 {
    font-size: 2.6666667em;
    line-height: 1;
    color: #111827;
    font-weight: 800;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:not(.tab-link) {
    text-decoration: none;
    @apply text-orange-500;
    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    padding-left: 1.5rem;
  }

  code {
    @apply text-orange-500;
    font-weight: 400;
    font-size: 1rem;
  }
  pre code {
    @apply text-gray-200;
  }

  img {
    cursor: zoom-in;
  }

  ul.contains-task-list {
    list-style: none;
    li.task-list-item {
      padding-left: 0;
      *:first-child {
        margin-top: 0.3rem;
      }
      *:last-child {
        margin-bottom: 0.3rem;
      }
      &:before {
        display: none;
      }
    }
  }

  .token.class-name,
  .token.function,
  .token.selector,
  .token.selector .class,
  .token.tag {
    --tw-text-opacity: 1;
    color: rgba(232, 121, 249, var(--tw-text-opacity));
  }

  .token.attr-name,
  .token.important,
  .token.keyword,
  .token.operator,
  .token.pseudo-class,
  .token.rule {
    --tw-text-opacity: 1;
    color: rgba(34, 211, 238, var(--tw-text-opacity));
  }

  .token.attr-value,
  .token.class,
  .token.color,
  .token.number,
  .token.string,
  .token.unit {
    --tw-text-opacity: 1;
    color: rgba(190, 242, 100, var(--tw-text-opacity));
  }

  .token.module,
  .token.property,
  .token.punctuation {
    --tw-text-opacity: 1;
    color: rgba(186, 230, 253, var(--tw-text-opacity));
  }

  .token.atapply .token:not(.rule):not(.important) {
    color: inherit;
  }

  .language-shell .token:not(.comment) {
    color: inherit;
  }

  .language-css .token.function {
    color: inherit;
  }

  .token.comment {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .token.deleted:not(.prefix) {
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }

  .token.deleted:not(.prefix):after {
    content: "";
    --tw-bg-opacity: 1;
    background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.25;
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .token.deleted.prefix {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .token.inserted:not(.prefix) {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.5;
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .token.inserted.prefix {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
    --tw-text-opacity: 0.75;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mce-content-body [contentEditable="false"]:not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding-top: 1em;
    padding-right: 1.5em;
    padding-bottom: 1em;
    padding-left: 1.5em;
    color: #e5e7eb;
    background-color: #1f2937;
    text-shadow: none !important;
  }
  .language-css .token.string,
  .style .token.string,
  .token.entity,
  .token.operator,
  .token.url {
    background: none;
    color: rgb(34, 211, 238);
  }

  .token.boolean,
  .token.constant,
  .token.deleted,
  .token.number,
  .token.property,
  .token.symbol,
  .token.tag {
    color: rgb(229, 231, 235);
  }

  .mce-content-body:focus-visible {
    outline: none;
  }
}

.tox-tinymce-aux {
  z-index: 9999999 !important;
}
